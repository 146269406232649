<template>
  <div>
    <Preloader v-if="loading" />
    <div v-if="invites.length" class="card-team">
      <div v-for="invite in invites" :key="invite.id" class="card-team__item">
        <div class="card-team__logo">
          <img src="@/assets/img/team/team.svg" alt="Card logo">
        </div>
        <div class="card-team__info">
          <h2 class="card-team__title title title--theme">{{ invite.team.name }}</h2>
          <p v-if="invite.team.desc" class="card-team__desc">{{ invite.team.desc }}</p>
          <div class="card-team__stats">
            <div class="card-team__stat">
              <img src="@/assets/img/team/members.svg" alt="Member icon">
              <span>Кол-во участников: <b>{{ invite.team.members_count }}</b></span>
            </div>
            <div class="card-team__stat">
              <img src="@/assets/img/team/calendar.svg" alt="Calendar icon">
              <span>Дата приглашения: <b>{{ invite.created_at }}</b></span>
            </div>
          </div>
          <div class="card-team__controls">
            <button
              @click="onRejectInvite($event, invite.id)"
              type="button"
              class="button button--mini button--red"
            >
              Отклонить
            </button>
            <button
              @click="onAcceptInvite($event, invite.id)"
              type="button"
              class="button button--mini button--green"
            >
              Принять
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!loading" class="slug">
      <div class="slug__img">
        <img src="@/assets/img/team/team-slug.svg" alt="Slug">
      </div>
      <p class="slug__text">У Вас нет приглашений</p>
    </div>
    <paginate
      v-if="pages > 1"
      v-model="page"
      :page-count="pages"
      :clickHandler="onPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import Preloader from '@/components/layout/Preloader'

export default {
  name: 'MyTeamsInvitations',
  components: {
    Preloader,
  },
  data() {
    return {
      invites: [],
      page: 1,
      pages: 0,
      loading: true,
    }
  },
  mounted() {
    this.fetchInvitations()
  },
  methods: {
    fetchInvitations(page = 1, limit = 8) {
      this.$store.dispatch('teams/GET_INVITATIONS', { page, limit })
        .then(response => {
          this.loading = false
          this.invites = response.data.results
          this.pages = response.data.pages
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка получения данных'
          })
        })
    },
    onAcceptInvite(e, id) {
      this.$store.dispatch('teams/POST_ACCEPT', { id })
        .then(() => {
          this.fetchInvitations()
          this.$notify({
            type: 'success',
            title: 'Внимание!',
            text: 'Вы приняли приглашение'
          })
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка приглашения'
          })
        })
    },
    onRejectInvite(e, id) {
      this.$store.dispatch('teams/POST_REJECT', { id })
        .then(() => {
          this.fetchInvitations()
          this.$notify({
            type: 'success',
            title: 'Внимание!',
            text: 'Вы отказались от приглашения'
          })
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка отказа'
          })
        })
    },
    onPaginationCallback(page) {
      this.fetchInvitations(page)
    },
  }
}
</script>
